import axios from 'axios'
import { print } from 'graphql/language/printer'

/**
 * @param {String} endpoint - API endpoint
 * @param {String} query - GraphQL query
 * @returns encoded string containing API endpoint and graphql query
 */
function createEncodedQuery(endpoint, query) {
    return `${endpoint}?query=${encodeURIComponent(query)}`
}

/**
 * @param {String} sessionStorageKey - Key
 * @param {DocumentNode} query - GraphQL gql tag
 * @param {Object} variables - example: { id: "1336" }
 * @param {String} endpoint - API endpoint
 * @returns null
 */

export default async function createFetchwithSessionStorage(
    sessionStorageKey,
    query,
    variables,
    endpoint
) {
    /**
     * If sessionStorage is not supported, fetch data
     * If sessionStorage contains a key matching the sessionStorageKey from the function params,
     * skip fetching data
     */
    if (
        typeof window.sessionStorage !== 'object' ||
        sessionStorage.getItem(sessionStorageKey)
    ) {
        return null
    }

    /**
     * Use the GraphQL util function print to turn a gql-tag into a formatted string
     */
    const stringifiedQuery = print(query)
    const requestUrl = createEncodedQuery(endpoint, stringifiedQuery)

    try {
        const response = await axios.get(requestUrl, {
            params: {
                variables,
            },
        })

        return sessionStorage.setItem(
            sessionStorageKey,
            JSON.stringify(response.data.data)
        )
    } catch (error) {
        return null
    }
}
