import { findAll } from '../../utils/dom'

const columnSelector = '.footer__column'
const expandedAttr = 'aria-expanded'

export default function Footer({ refs }) {
    const columns = findAll(columnSelector, refs.items)
    const isDesktop = window.matchMedia('(min-width: 768px)')

    // Toggle data attributes to show or hide section
    const toggleSection = e => {
        const column = e.target.closest(columnSelector)
        const expandedAttrValue =
            column.getAttribute(expandedAttr) === 'false' ? 'true' : 'false'

        column.setAttribute(expandedAttr, expandedAttrValue)
    }

    const setExpandListeners = () => {
        columns.forEach(item => {
            ;['click', 'touch'].forEach(event =>
                item.addEventListener(event, toggleSection, false)
            )
        })
    }

    const resetExpandListeners = () => {
        columns.forEach(item => {
            ;['click', 'touch'].forEach(event =>
                item.removeEventListener(event, toggleSection, false)
            )
        })
    }

    // Check desktop on initial load
    if (!isDesktop.matches) {
        setExpandListeners()
    }

    // Listen for viewport changes
    isDesktop.addListener(({ matches }) => {
        if (matches) {
            resetExpandListeners()
        } else {
            setExpandListeners()
        }
    })
}
