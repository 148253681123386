// JS for simple non-react menu drawer - desktop
export default function MenuDrawerDesktop({ refs }) {
    const toggleDrawer = () => {
        refs.expandSection.classList.toggle('expanded')
        refs.open.classList.toggle('hidden')
        refs.close.classList.toggle('hidden')
    }

    refs.open.addEventListener('click', () => {
        toggleDrawer(true)
    })

    refs.close.addEventListener('click', () => {
        toggleDrawer(true)
    })

    refs.submit.addEventListener('click', () => {
        toggleDrawer(true)
    })
}
