import { iframeResizer } from 'iframe-resizer'
import postscribe from 'postscribe'

export default function ZonalWidget({ props, root }) {
    const scrolling = props.allowScrolling ? 'auto' : false

    const url = props.fezurl

    const triggerResize = iframe => {
        iframeResizer(
            {
                checkOrigin: false,
                minHeight: props.minHeight,
                maxWidth: props.maxWidth,
                heightCalculationMethod: 'taggedElement',
                scrolling,
            },
            iframe
        )
    }

    const observer = new MutationObserver(() => {
        const iframe = root.querySelector('iframe')

        if (iframe) {
            triggerResize(iframe)
            observer.disconnect() // stop observing
        }
    })

    // start observing
    observer.observe(root, {
        childList: true,
        subtree: true,
    })

    const loadLiveResWidget = url => {
        const widget = document.getElementById('LiveResWidget')

        const isLoaded = widget.querySelectorAll('.widget-script')

        if (isLoaded.length > 0) {
            return
        }

        postscribe(
            widget,
            `<script class='widget-script' src=${url}></script>`,
            {
                error: () => {
                    postscribe(
                        widget,
                        "<div class='live-res__wrap-error'><div class='header-item__heading'><h2>Sorry, We Cannot Take Online Bookings At The Moment</h2></div> <p class='header-item__standfirst'>This is a temporary problem. Please try again shortly or contact the venue.</p></div>"
                    )
                },
            }
        )
    }

    window.addEventListener('load', () => {
        loadLiveResWidget(url)
        triggerResize()
    })
}
