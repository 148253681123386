import VENUE_LOCATION_QUERY from '../../react/shared/graphql/venueLocation'
import createFetchwithSessionStorage from '../../utils/createFetchwithSessionStorage'

/**
 * @param {String} sessionStorageKey
 * @param {String} venueId - ID of the venue
 */
export default async function getVenueLocation(
    sessionStorageKey,
    venueId,
    endpoint
) {
    return createFetchwithSessionStorage(
        sessionStorageKey,
        VENUE_LOCATION_QUERY,
        { id: venueId },
        endpoint
    )
}
