import getVenueDetails from '../shared/getVenueDetails'
import deferUntilObserved from '../../utils/deferUntilObserved'

async function VenueInfo({ props, root }) {
    const { default: init } = await import(
        /* webpackChunkName: "venue-info" */ '../../react/venue-info'
    )

    const { venueId, graphqlApi } = window.__initialState[props.initialStateId]

    await getVenueDetails(venueId, graphqlApi)

    init(root, props)
}

export default deferUntilObserved(VenueInfo)
