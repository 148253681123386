import gql from 'graphql-tag'

const MENUS = gql`
    query Menus($venueId: String!) {
        menus(venueId: $venueId) {
            id
            name
            slug
            description
            image
            link
            subtitle
        }
    }
`

export default MENUS
