import gql from 'graphql-tag'

const VENUE_LOCATION_QUERY = gql`
    query Venue($id: ID!) {
        venue(id: $id) {
            address {
                line1
                line2
                line3
                county
                postcode
            }
            phone
            location {
                latitude
                longitude
            }
            brand {
                name
            }
        }
    }
`

export default VENUE_LOCATION_QUERY
