/* eslint-disable no-console */

/**
 * This function makes tables responsive
 */
export default function ResponsiveTable({ root }) {
    /**
     * Add table headings to each row as ::before elements and wrap table in a container
     */
    ;(function responsiveTable() {
        try {
            const headingsArr = []
            const thElements = root
                .querySelectorAll('tr')[0]
                .querySelectorAll('td')

            thElements.forEach(th => {
                const headingText = th.innerHTML

                headingsArr.push(headingText)
            })

            const styleElem = document.createElement('style')

            document.head.appendChild(styleElem)
            const styleSheet = styleElem.sheet

            headingsArr.forEach((el, i) => {
                styleSheet.insertRule(
                    `table td:nth-child(${i + 1})::before {content:"${
                        headingsArr[i]
                    }";}`,
                    styleSheet.cssRules.length
                )
            })

            // add scrollable container around the table
            const container = document.createElement('div')

            container.classList.add('table-container')
            root.parentNode.insertBefore(container, root)
            container.appendChild(root)
        } catch (e) {
            console.error(`responsiveTable(): ${e}`)
        }
    })()

    /**
     * Changing table display from table removes all default semantics.
     * This functions adds the necessary aria back.
     */
    ;(function addTableARIA() {
        /**
         * Add role to table elements and remove inline browser/CMS styles for table
         * @param {NodeList} elements
         * @param {String} value role value
         */
        const enhanceElement = (elements, value) => {
            elements.forEach(element => {
                element.setAttribute('role', value)
                element.removeAttribute('style')
            })
        }

        try {
            const allRowGroups = root.querySelectorAll('thead, tbody, tfoot')
            const allRows = root.querySelectorAll('tr')
            const allCells = root.querySelectorAll('td')
            const allHeaders = root.querySelectorAll('tr:first-of-type > td')

            enhanceElement([root], 'table')
            enhanceElement(allRowGroups, 'rowgroup')
            enhanceElement(allRows, 'row')
            enhanceElement(allCells, 'cell')
            enhanceElement(allHeaders, 'columnheader')
        } catch (e) {
            console.error(`addTableARIA(): ${e}`)
        }
    })()
}
