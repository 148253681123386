import 'svgxuse' // HTML: External SVG <use>
import * as components from './components'
import checkPositionStickySupport from '../utils/checkPositionStickySupport'
import dataAttrs from '../utils/dataAttrs'
import { findAll } from '../utils/dom'
import isInternalCmsLink from '../utils/isInternalCmsLink'
import scrollPage from '../utils/scrollPage'
import toCamelCase from '../utils/toCamelCase'

const COMPONENT_IDENTIFIER = 'data-component'
const REF_IDENTIFIER = 'data-ref'

function execComponent(root, func) {
    // Extract props from root node
    const props = dataAttrs(root).reduce((obj, { key, value: rawValue }) => {
        // Ignore component and ref attributes
        if (key !== 'component' && key !== 'ref') {
            let value = rawValue

            // Convert
            if (rawValue === 'true') {
                value = true
            } else if (rawValue === 'false') {
                value = false
            }

            // eslint-disable-next-line no-param-reassign
            obj[toCamelCase(key)] = value
        }

        return obj
    }, {})

    // Cache referenced DOM nodes
    const refs = findAll(`[${REF_IDENTIFIER}]`, root).reduce((obj, node) => {
        const refName = node.getAttribute(REF_IDENTIFIER)
        const formattedRefName = toCamelCase(refName)

        if (
            node.closest(`[${COMPONENT_IDENTIFIER}]`) === root &&
            formattedRefName !== ''
        ) {
            // eslint-disable-next-line no-param-reassign
            obj[formattedRefName] = node
        }

        return obj
    }, {})

    func({ props, refs, root })
}

// START: move somewhere else

// Calculate scroll position for anchor links and factor in the navigation so that
// it doesn't overlap the target. Ignore links inside of apps.
const scrollToPosition = anchor => {
    const nav = document.body.querySelector('[data-component="navigation"]')
    let scrollTop = 0

    if (anchor && nav) {
        const element = document.body.querySelector(`${anchor}`)

        if (element) {
            const navOffset = checkPositionStickySupport()
                ? nav.clientHeight || 0
                : 0

            scrollTop =
                element.getBoundingClientRect().top +
                window.pageYOffset -
                navOffset -
                20

            if (scrollTop < 0) {
                scrollTop = 0
            }
        }
    }
    scrollPage(scrollTop)
}

document.body.addEventListener('click', ({ target }) => {
    if (isInternalCmsLink(target)) {
        scrollToPosition(target.href.substring(target.href.indexOf('#')))
    }
})

if (window.location.hash) {
    window.addEventListener('load', () => {
        scrollToPosition(window.location.hash)
    })
}

// END: move somewhere else

/*  Add JavaScript to tables that come from CMS text editor to allow for responsive behaviour.
    If there is a table with already applied styling, don't use the defaults */
const allTables = document.querySelectorAll('table')

Array.from(allTables).forEach(tableElem => {
    if (tableElem.classList.length === 0) {
        tableElem.classList.add('responsive-table')
        tableElem.setAttribute('data-component', 'responsive-table')
    }
})

findAll(`[${COMPONENT_IDENTIFIER}]`, document.body).forEach(node => {
    const componentName = node.getAttribute(COMPONENT_IDENTIFIER)
    const functionName = toCamelCase(componentName, true)
    const component = components[functionName]

    if (!component) {
        if (process.env.NODE_ENV !== 'production' && window.console) {
            // eslint-disable-next-line no-console
            console.warn(
                `\`data-component="${componentName}"\` found in HTML but no component named \`${functionName}\` exported in \`cms/components/index.js\``
            )
        }

        return
    }

    execComponent(node, component)
})
