import { children } from '../../utils/dom'

export default function Calendar({ refs }) {
    const paginationHidden = 'pagination--hidden'
    const desktopMq = window.matchMedia('(min-width: 768px)')

    const totalPages = refs.items.children.length
    const itemEls = children(refs.items)
    let currentPage = 1
    let showPagination = window.matchMedia && !desktopMq.matches

    // eslint-disable-next-line no-param-reassign
    refs.total.textContent = totalPages

    const toggleDisabled = (element, disabled) => {
        // eslint-disable-next-line no-unused-expressions
        disabled
            ? element.setAttribute('disabled', true)
            : element.removeAttribute('disabled')
    }

    const render = () => {
        // Updating pagination counts
        // eslint-disable-next-line no-param-reassign
        refs.current.textContent = currentPage

        // Show/hide pagination if we more than one page
        // eslint-disable-next-line no-unused-expressions
        !showPagination || totalPages <= 1
            ? refs.pagination.classList.add(paginationHidden)
            : refs.pagination.classList.remove(paginationHidden)

        // Toogle disabled/enabled state of buttons
        toggleDisabled(refs.prev, currentPage <= 1)
        toggleDisabled(refs.next, currentPage >= totalPages)

        // Show correct page
        // eslint-disable-next-line no-param-reassign
        refs.items.style.transform = `translateX(${
            showPagination ? (currentPage - 1) * -100 : 0
        }%)`

        // Update children
        itemEls.forEach((page, index) =>
            page.setAttribute(
                'aria-hidden',
                showPagination && index + 1 !== currentPage
            )
        )
    }

    const goto = page => {
        // Only update the current page state if page is with range 1-{total}
        if (page >= 1 && page <= totalPages) {
            currentPage = page

            render()
        }
    }

    // Add media query listener
    desktopMq.addListener(({ matches }) => {
        showPagination = !matches

        render()
    })

    refs.prev.addEventListener('click', () => {
        goto(currentPage - 1)
    })

    refs.next.addEventListener('click', () => {
        goto(currentPage + 1)
    })

    render()
}
