import axios from 'axios'

// NOTE: Some of classes used below are specific to the reviews widget loaded
// If the markup is changed by the third party these classes will also
// need to be updated - this is an external dependency

export default function ReviewWidget({ root, refs }) {
    const { venueId } = root.dataset
    const compactUrl = `https://widgets.reputation.com/widgets/5fb66cbfe539e600061d53e0/run?tk=c41a145ec8b&lc=${venueId}`
    const ratingOnlyUrl = `https://widgets.reputation.com/widgets/5fdb1547758bc000062de6b8/run?tk=c41a145ec8b&lc=${venueId}`
    const { reputationContainer, googleContainer } = refs

    axios
        .all([axios.get(compactUrl), axios.get(ratingOnlyUrl)])
        .then(
            axios.spread((firstResponse, secondResponse) => {
                reputationContainer.innerHTML = `${firstResponse.data} ${secondResponse.data}`
            })
        )
        .then(() => {
            // Add alt to Google logo
            reputationContainer
                .querySelector('.icon > .source')
                .setAttribute('alt', 'Google logo')

            // Get rating value to use it in the accessible label
            const valueElement = reputationContainer.querySelector('.value')
            const maxElement = reputationContainer.querySelector('.max')
            const ratingLabel = `Rating ${
                valueElement.textContent
            } out of ${maxElement.textContent.replace(' /', '')}`

            // Hide elements from screen-readers
            reputationContainer
                .querySelector('.stars-container')
                .setAttribute('aria-hidden', true)

            reputationContainer
                .querySelector('.stars.empty')
                .setAttribute('aria-hidden', true)

            reputationContainer
                .querySelector('.rating-value')
                .setAttribute('aria-hidden', true)

            // Create a new widget using elements of the hidden widgets requested above
            googleContainer.innerHTML = `
            <div class="r4e-widget summary server">
              <div itemscope itemtype="http://schema.org/Organization">
                ${reputationContainer.querySelector('meta').outerHTML}
                <div class="summary" itemprop="aggregateRating" itemscope="" itemtype="http://schema.org/AggregateRating">
                  <div class="source-block" style="border-top:none">
                    <div class="brand">
                      ${reputationContainer.querySelector('.icon').outerHTML}
                      <p>Google Reviews</p>
                    </div>
                    <div class="rating-only">
                    <div class="rating">
                        ${
                            reputationContainer.querySelector(
                                '.stars-container'
                            ).outerHTML
                        }
                        <div class="review-info">
                        <span class="screen-reader-only">
                           ${ratingLabel}
                        </span>
                          ${
                              reputationContainer.querySelector('.rating-value')
                                  .outerHTML
                          }
                          ${
                              reputationContainer.querySelector('.count a')
                                  .outerHTML
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            `
        }) // eslint-disable-next-line
        .catch(error => console.log(error))
}
