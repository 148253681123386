import axios from 'axios'
import deferUntilObserved from '../../utils/deferUntilObserved'
import parseHTMLStr from '../../utils/parseHTMLStr'

const ajaxString = '&isAjax=true'

async function BlogList({ root }) {
    const cardList = root.querySelector('.card-list')

    const updatePosts = (htmlStr, link, posts) => {
        const html = parseHTMLStr(htmlStr)
        const newPosts = html.querySelectorAll(
            '.card-list ul li.card-list__post'
        )
        const newLink = html.querySelectorAll('#show-more-posts')

        posts.append(...newPosts)

        if (newLink.length) {
            link.replaceWith(newLink[0])
        } else {
            link.parentNode.removeChild(link)
        }
    }

    const setUpShowMoreButton = (cardList, posts) => {
        cardList.addEventListener('click', event => {
            const moreLink = cardList.querySelector('#show-more-posts')

            if (event.target && moreLink) {
                event.preventDefault()
                moreLink.classList.add('button--is-loading')

                const url = `${moreLink.getAttribute('href')}${ajaxString}`

                axios
                    .get(url)
                    .then(response => {
                        // handle success
                        updatePosts(response.data, moreLink, posts)
                    })
                    .catch(error => {
                        // eslint-disable-next-line no-console
                        console.error(error)
                    })
                    .then(() => {
                        moreLink.classList.remove('button--is-loading')
                    })
            }
        })
    }

    // Set up blog list behaviour
    if (cardList) {
        const [posts] = cardList.querySelectorAll('#posts')

        setUpShowMoreButton(cardList, posts)
    }
}

export default deferUntilObserved(BlogList)
