// JS for simple non-react menu drawer - mobile
export default function MenuDrawerMobile({ refs }) {
    const toggleDrawer = () => {
        refs.expandSection.classList.toggle('drawer-open')
    }

    refs.open.addEventListener('click', () => {
        toggleDrawer(true)
    })

    refs.close.addEventListener('click', () => {
        toggleDrawer(true)
    })

    refs.overlay.addEventListener('click', () => {
        toggleDrawer(true)
    })

    refs.submit.addEventListener('click', () => {
        toggleDrawer(true)
    })
}
