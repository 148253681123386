import Flickity from 'flickity'
import 'flickity-imagesloaded'
import 'flickity-fade'

export default function Carousel({ props, refs }) {
    const autoScrollenabled = JSON.parse(props.autoScroll.toLowerCase())

    let autoScroll
    let transitionStyle

    if (autoScrollenabled && props.transitionTime > 0) {
        autoScroll = props.transitionTime * 1000
    } else {
        autoScroll = autoScrollenabled
    }

    if (props.noSlides < 2) {
        return false
    }

    if (props.transitionStyle === 'Fade') {
        transitionStyle = true
    }

    const carouselOptions = {
        autoPlay: autoScroll,
        cellSelector: '.carousel__item',
        contain: true,
        groupCells: true,
        imagesloaded: true,
        adaptiveHeight: true,
        fade: transitionStyle,
    }
    /* eslint-disable-next-line no-unused-vars */
    const heroCarousel = new Flickity(refs.slides, {
        ...carouselOptions,
    })

    const carouselLoaded = () => {
        /*  because the carousel item element is complex, ie: a postioned absolute image
                with another flexbox overlay. occasionaly the slider will execute before
                the layout has fully rendered and miscalculate the layout.
                Triggering a resize event after onload ready resolves this issue */
        heroCarousel.resize()
    }

    window.addEventListener('load', () => {
        carouselLoaded()
    })
}
