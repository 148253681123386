import deferUntilObserved from '../../utils/deferUntilObserved'

async function BookASpace({ props, root }) {
    const { default: init } = await import(
        /* webpackChunkName: "book-a-space" */ '../../react/book-a-space'
    )

    init(root, props)
}

export default deferUntilObserved(BookASpace)
