import gql from 'graphql-tag'

const VENUE_DETAILS = gql`
    query Venue($id: ID!) {
        venue(id: $id) {
            name
            brand {
                name
            }
            phone
            address {
                line1
                line2
                line3
                county
                postcode
            }
            urls {
                book
            }
            openingTimesToday
            specialHoursMessage
            closed
            groupedServingHours {
                name
                servingTimes
            }
            groupedOperatingHours {
                name
                openingTimes
                servingTimes
                specialHoursMessage
            }
        }
    }
`

export default VENUE_DETAILS
