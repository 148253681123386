import gql from 'graphql-tag'

const MENU_VENUE = gql`
    query Venue($id: ID!) {
        venue(id: $id) {
            name
            html {
                descriptions {
                    searchable
                }
            }
            urls {
                book
            }
            servingTimesToday
            groupedServingHours {
                name
                specialHoursMessage
                servingTimes
            }
        }
    }
`

export default MENU_VENUE
