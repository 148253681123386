import deferUntilObserved from '../../utils/deferUntilObserved'
import getVenueLocation from '../shared/getVenueLocation'

async function PubMap({ props, root }) {
    const { default: init } = await import(
        /* webpackChunkName: "pub-map" */ '../../react/pub-map'
    )

    const { venueId, graphqlApi } = window.__initialState[props.initialStateId]

    await getVenueLocation(`${venueId}-map-location`, venueId, graphqlApi)

    init(root, props)
}

export default deferUntilObserved(PubMap)
