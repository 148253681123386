import { iframeResizer } from 'iframe-resizer'

export default function ResponsiveIframe({ props, refs, root }) {
    const iframe = refs.iframe || root.querySelector('iframe')
    const scrolling = props.allowScrolling ? 'auto' : false

    if (iframe) {
        iframeResizer(
            {
                checkOrigin: false,
                minHeight: props.minHeight,
                maxWidth: props.maxWidth,
                heightCalculationMethod: 'taggedElement',
                scrolling,
            },
            iframe
        )
    }
}
