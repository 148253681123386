import deferUntilObserved from '../../utils/deferUntilObserved'

async function VenueSearch({ props, root }) {
    const { default: init } = await import(
        /* webpackChunkName: "venue-search" */ '../../react/venue-search'
    )

    init(root, props)
}

export default deferUntilObserved(VenueSearch)
