export default function dataAttrs(node) {
    const formatAttr = (key, value) => ({ key, value })

    if (node.dataset) {
        return Object.keys(node.dataset).map(key =>
            formatAttr(key, node.dataset[key])
        )
    }
    // Return a map of data- prefixed attributes
    return Array.prototype.filter
        .call(node.attributes, attr => attr.nodeName.match(/data-(.*)/))
        .map(attr =>
            formatAttr(attr.nodeName.replace('data-', ''), attr.nodeValue)
        )
}
