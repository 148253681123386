export default function canTouch() {
    // var to indicate current input type (is touch versus no touch)
    let isTouch = false
    let isTouchTimer
    // var indicating current document root class ("can-touch" or "")
    let curRootClass = ''

    const addtouchclass = () => {
        clearTimeout(isTouchTimer)
        isTouch = true
        if (curRootClass !== 'can-touch') {
            // add "can-touch' class if it's not already present
            curRootClass = 'can-touch'
            document.documentElement.classList.add(curRootClass)
        }
        isTouchTimer = setTimeout(() => {
            isTouch = false
        }, 500)
        // maintain "istouch" state for 500ms so removetouchclass
        // doesn't get fired immediately following a touch event
    }

    const removetouchclass = () => {
        if (!isTouch && curRootClass === 'can-touch') {
            // remove 'can-touch' class if not triggered by a touch event and class is present
            isTouch = false
            curRootClass = ''
            document.documentElement.classList.remove('can-touch')
        }
    }

    document.addEventListener('touchstart', addtouchclass, false) // this event only gets called when input type is touch
    document.addEventListener('mouseover', removetouchclass, false) // this event gets called when input type is everything from touch to mouse/trackpad
}
