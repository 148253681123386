import isInternalCmsLink from '../../utils/isInternalCmsLink'
import { findAll } from '../../utils/dom'

export default function Faqs({ refs }) {
    const triggers = findAll('.faqs__trigger', refs.items)
    const answers = findAll('.faqs__answer', refs.items)
    const expandedAttr = 'aria-expanded'
    const hiddenAttr = 'hidden'

    const checkShouldExpand = selectedTrigger => {
        return !(
            selectedTrigger.hasAttribute(expandedAttr) &&
            selectedTrigger.getAttribute(expandedAttr) === 'true'
        )
    }

    const toggleAnswerExpanded = selectedTrigger => {
        // If there is no question, exit
        if (!selectedTrigger) {
            return
        }

        const shouldExpand = checkShouldExpand(selectedTrigger)
        const selectedAnswer = selectedTrigger.parentNode.nextElementSibling

        // Update triggers and answers
        triggers.forEach(trigger =>
            trigger.setAttribute(
                expandedAttr,
                trigger === selectedTrigger && shouldExpand
            )
        )
        answers.forEach(answer =>
            answer === selectedAnswer && shouldExpand
                ? answer.removeAttribute(hiddenAttr)
                : answer.setAttribute(hiddenAttr, '')
        )
    }

    const getTriggerFromHashOrLink = link => {
        // eslint-disable-next-line no-nested-ternary
        const item = link
            ? refs.items.querySelector(link.substring(link.indexOf('#')))
            : window.location.hash
            ? refs.items.querySelector(window.location.hash)
            : null

        if (item) {
            if (item.classList.contains('faqs__trigger')) {
                return item
            }

            const selectedAnswer = item.closest('.faqs__answer')

            if (selectedAnswer) {
                return refs.items.querySelector(
                    `[aria-controls=${item.closest('.faqs__answer').id}]`
                )
            }
        }

        return null
    }

    // Listen to clicks on items
    refs.items.addEventListener('click', event => {
        const selectedTrigger = event.target.closest('.faqs__trigger')

        toggleAnswerExpanded(selectedTrigger)
    })

    // Open item if active anchor is inside
    const trigger = getTriggerFromHashOrLink()

    if (trigger && checkShouldExpand(trigger)) {
        toggleAnswerExpanded(trigger)
    }

    // Listen to clicks on anchor links that may need to open an item
    document.body.addEventListener('click', event => {
        if (isInternalCmsLink(event.target)) {
            const trigger = getTriggerFromHashOrLink(event.target.href)

            if (trigger && checkShouldExpand(trigger)) {
                toggleAnswerExpanded(trigger)
            }
        }
    })
}
