/* eslint-disable no-param-reassign */
export default function HeaderItem({ props, refs }) {
    const renderVideo = () => {
        // If we don't have a video tag, create it
        if (!refs.video) {
            refs.video = document.createElement('video')
            refs.video.classList.add('header-item__video')
            refs.video.autoplay = true
            refs.video.loop = true
            refs.video.muted = true
        }

        // Update video source
        refs.video.src = props.video

        // Add it to the DOM
        refs.background.appendChild(refs.video)
    }

    // If we have a video
    if (props.video) {
        const desktopMq = window.matchMedia('(min-width: 768px)')

        // If we're on desktop, render the video
        if (desktopMq.matches) {
            renderVideo()
        }

        // On mobile/desktop change...
        // Show the video on desktop or detach it from the DOM on mobile
        desktopMq.addListener(mq => {
            if (mq.matches) {
                renderVideo()
            } else if (refs.video) {
                refs.background.removeChild(refs.video)
            }
        })
    }
}
