import VENUE_DETAILS from '../../react/shared/graphql/venueDetails'
import createFetchwithSessionStorage from '../../utils/createFetchwithSessionStorage'

/**
 * @param {string} venueId - ID of the venue
 */
export default async function getVenueDetails(venueId, endpoint) {
    return createFetchwithSessionStorage(
        venueId,
        VENUE_DETAILS,
        { id: venueId },
        endpoint
    )
}
