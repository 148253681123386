export default function GkwfForm({ root }) {
    const form = root
    const submitButton = form.querySelector('button[type="submit"]')

    form.addEventListener('submit', () => {
        const errorsPromise = new Promise(resolve => {
            setTimeout(() => {
                resolve(form.querySelectorAll('.field-validation-error'))
            }, 100)
        })

        errorsPromise.then(errors => {
            if (!errors.length) {
                submitButton.disabled = true
            }
        })
    })
}
