import deferUntilObserved from '../../utils/deferUntilObserved'

async function MatchPint({ props, root }) {
    const { default: init } = await import(
        /* webpackChunkName: "match-pint" */ '../../react/match-pint'
    )

    init(root, props)
}

export default deferUntilObserved(MatchPint)
