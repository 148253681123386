import deferUntilObserved from '../../utils/deferUntilObserved'

async function PubHours({ props, root }) {
    const { default: init } = await import(
        /* webpackChunkName: "pub-map" */ '../../react/pub-hours'
    )

    init(root, props)
}

export default deferUntilObserved(PubHours)
