export default function Accordion({ root }) {
    const accordionItems = Array.from(root.children)

    const triggers = Array.from(root.querySelectorAll('.accordion__button'))

    const toggleAria = (element, attribute) => {
        const opposite =
            element.getAttribute(attribute) === 'true' ? 'false' : 'true'

        element.setAttribute(attribute, opposite)
    }

    const toggleAccordion = accordionTrigger => {
        toggleAria(accordionTrigger, 'aria-expanded')
        const targetPanel = accordionTrigger
            .closest('.accordion__item')
            .querySelector('.accordion__panel')

        toggleAria(targetPanel, 'aria-hidden')
    }

    accordionItems.forEach(element => {
        element.addEventListener('click', event => {
            const { target } = event

            if (target.dataset.accordionComponent === 'AccordionItemButton') {
                toggleAccordion(target)
            }
        })
    })

    // Bind keyboard behaviors on the main accordion container
    root.addEventListener('keydown', event => {
        const { target } = event
        const key = event.which.toString()

        // 33 = Page Up, 34 = Page Down
        const ctrlModifier = event.ctrlKey && key.match(/33|34/)

        // Is this coming from an accordion header?
        if (target.classList.contains('accordion__button')) {
            // toggle the accordion on enter
            if (key.match(/13/)) {
                toggleAccordion(target)
            }
            // Up/ Down arrow and Control + Page Up/ Page Down keyboard operations
            // 38 = Up, 40 = Down
            if (key.match(/38|40/) || ctrlModifier) {
                const index = triggers.indexOf(target)
                const direction = key.match(/34|40/) ? 1 : -1
                const { length } = triggers
                const newIndex = (index + length + direction) % length

                triggers[newIndex].focus()

                event.preventDefault()
            } else if (key.match(/35|36/)) {
                // 35 = End, 36 = Home keyboard operations
                switch (key) {
                    // Go to first accordion
                    case '36':
                        triggers[0].focus()
                        break
                    // Go to last accordion
                    case '35':
                        triggers[triggers.length - 1].focus()
                        break
                    default:
                    // do nothing
                }
                event.preventDefault()
            }
        }
    })
}
