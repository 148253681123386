import cookies from 'js-cookie'

export default function Banner({ props, refs, root }) {
    const toggleBanner = hide => {
        root.classList.toggle(props.hiddenClass, hide)
    }

    refs.close.addEventListener('click', () => {
        cookies.set(props.key, 1, { expires: 365 })

        toggleBanner(true)
    })

    toggleBanner(!!cookies.get(props.key))
}
