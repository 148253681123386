import PubSub from 'pubsub-js'
import { PROMO_PUB_FINDER_SEARCH } from '../../react/pub-finder/consts'
import canTouch from '../../utils/canTouch'

export default function Navigation({ refs, root }) {
    let showMenu = false
    const isDesktop = window.matchMedia('(min-width: 960px)')
    let dropdownOpen = false

    canTouch()

    const render = () => {
        root.classList.toggle('nav--open', showMenu)
        document.body.classList.toggle('no-scroll', showMenu)
    }

    const toggleMobileDropdowns = event => {
        // if there are dropdowns
        dropdownOpen = !dropdownOpen
        const activeDropdownClass = 'nav__menu-item--dropdown--active'

        if (refs.dropdownToggle.classList.contains(activeDropdownClass)) {
            // remove focus if closing menu
            refs.dropdownToggle.blur()
        }
        refs.dropdownToggle.classList.toggle(activeDropdownClass)
        refs.dropdownToggle.nextElementSibling.classList.toggle(
            'nav__sub-nav--open'
        )
        event.preventDefault()
    }

    const initDropdownListeners = () => {
        refs.dropdownToggle.addEventListener('click', toggleMobileDropdowns)
    }

    const resetDropdownListeners = () => {
        refs.dropdownToggle.removeEventListener(
            'click',
            toggleMobileDropdowns,
            false
        )
    }

    const handleDropDownResize = () => {
        isDesktop.addListener(({ matches }) => {
            if (matches) {
                resetDropdownListeners()
            } else {
                initDropdownListeners()
            }
        })
    }

    // Add media query listener to reset menu state
    isDesktop.addListener(({ matches }) => {
        if (matches && showMenu) {
            showMenu = false
            render()
        }
        if (matches && dropdownOpen) {
            toggleMobileDropdowns()
        }
    })

    // Listen for clicks on the toggle element
    if (refs.toggle) {
        refs.toggle.addEventListener('click', () => {
            showMenu = !showMenu
            render()
        })
    }

    if (refs.dropdownToggle) {
        if (!isDesktop.matches) {
            initDropdownListeners()
        }
        handleDropDownResize()
    }

    PubSub.subscribe(PROMO_PUB_FINDER_SEARCH, () => {
        showMenu = false
        render()
    })
}
