import { children, findAll } from '../../utils/dom'

export default function Products({ refs }) {
    const images = findAll('.products__item-image', refs.items)
    const products = children(refs.items)
    const desktopMq = window.matchMedia('(min-width: 768px)')

    const moveImagesOutside = () => {
        if (images && !!images.length) {
            images.forEach(image => {
                refs.largeImageContainer.appendChild(image)
            })
        }
    }

    const moveImagesInside = () => {
        images.forEach((image, index) => {
            const parent = products[index]

            parent.insertBefore(image, parent.firstChild)
        })
    }

    desktopMq.addListener(({ matches }) => {
        if (matches) {
            moveImagesOutside()
        } else {
            moveImagesInside()
        }
    })

    // Default is that the images are inside the products__item (small devices)
    // only move if large device
    if (desktopMq.matches) {
        moveImagesOutside()
    }
}
