// ('now' in window.performance ? performance.now() : new Date().getTime())
const getCurrentTime = () => +new Date()

export default function scrollPage(destination, options = {}) {
    const defaultOptions = { duration: 200, easing: t => t, complete: null }
    const start = window.pageYOffset
    const startTime = getCurrentTime()

    // Extend default options
    // eslint-disable-next-line no-param-reassign
    options = { ...defaultOptions, ...options }

    const documentHeight = Math.max(
        document.body.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.clientHeight,
        document.documentElement.scrollHeight,
        document.documentElement.offsetHeight
    )
    const windowHeight =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.getElementsByTagName('body')[0].clientHeight
    const destinationOffset =
        typeof destination === 'number' ? destination : destination.offsetTop
    const destinationOffsetToScroll = Math.round(
        documentHeight - destinationOffset < windowHeight
            ? documentHeight - windowHeight
            : destinationOffset
    )

    // If requestAnimationFrame is not supported, jump to end result
    if ('requestAnimationFrame' in window === false) {
        window.scroll(0, destinationOffsetToScroll)

        // eslint-disable-next-line no-unused-expressions
        options.complete && options.complete()
        return
    }

    function scroll() {
        const now = getCurrentTime()
        const time = now - startTime
        const percent = Math.min(time / options.duration, 1)
        const target = destinationOffset - start
        const newY = Math.ceil(start + target * options.easing(percent))

        window.scroll(0, newY)

        if (time < options.duration && destinationOffsetToScroll !== newY) {
            requestAnimationFrame(scroll)
        } else {
            // eslint-disable-next-line no-unused-expressions
            options.complete && options.complete()
        }
    }

    scroll()
}
