import deferUntilObserved from '../../utils/deferUntilObserved'

async function PubFinder({ props, root }) {
    const { default: init } = await import(
        /* webpackChunkName: "pub-finder" */ '../../react/pub-finder'
    )

    init(root, props)
}

export default deferUntilObserved(PubFinder)
