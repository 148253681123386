import getVenueDetails from '../shared/getVenueDetails'
import deferUntilObserved from '../../utils/deferUntilObserved'

async function OurVenue({ props, root }) {
    const { default: init } = await import(
        /* webpackChunkName: "our-venue" */ '../../react/our-venue'
    )

    const { venueId, venueApiUrl } = window.__initialState[
        props.initialStateId
    ].app

    await getVenueDetails(venueId, venueApiUrl)

    init(root, props)
}

export default deferUntilObserved(OurVenue)
