import deferUntilObserved from '../../utils/deferUntilObserved'

async function ImageGallery({ props, root }) {
    const { default: init } = await import(
        /* webpackChunkName: "image-gallery" */ '../../react/image-gallery'
    )

    init(root, props)
}

export default deferUntilObserved(ImageGallery)
