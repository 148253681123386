import deferUntilObserved from '../../utils/deferUntilObserved'

async function BookATable({ props, root }) {
    const { default: init } = await import(
        /* webpackChunkName: "booking-journey" */ '../../react/book-a-table'
    )

    init(root, props)
}

export default deferUntilObserved(BookATable)
