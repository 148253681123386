import MENUS from '../../react/shared/graphql/menus'
import createFetchwithSessionStorage from '../../utils/createFetchwithSessionStorage'

/**
 * @param {String} sessionStorageKey
 * @param {String} venueId - ID of the menu
 */
export default async function getMenus(sessionStorageKey, venueId, endpoint) {
    return createFetchwithSessionStorage(
        sessionStorageKey,
        MENUS,
        { venueId },
        endpoint
    )
}
