export default function deferUntilObserved(
    func,
    options = { threshold: 0.25 }
) {
    return function wrappedObservedComponent(config) {
        new IntersectionObserver(async ([entry], observer) => {
            if (!entry.isIntersecting) {
                return
            }

            observer.unobserve(config.root)

            func(config)
        }, options).observe(config.root)
    }
}
