import deferUntilObserved from '../../utils/deferUntilObserved'
import getMenuVenueDetail from '../shared/getMenuVenueDetail'
import getMenus from '../shared/getMenus'

async function Menu({ props, root }) {
    const { default: init } = await import(
        /* webpackChunkName: "menu" */ '../../react/menu'
    )

    const { venueId, venueApiUrl, menuApiUrl } = window.__initialState[
        props.initialStateId
    ].app

    await getMenuVenueDetail(`${venueId}-menu-venue-data`, venueId, venueApiUrl)
    await getMenus(`${venueId}-menus-data`, venueId, menuApiUrl)

    init(root, props)
}

export default deferUntilObserved(Menu)
