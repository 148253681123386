import MENU_VENUE from '../../react/shared/graphql/menuVenue'
import createFetchwithSessionStorage from '../../utils/createFetchwithSessionStorage'

/**
 * @param {String} sessionStorageKey
 * @param {String} venueId - ID of the menu
 */
export default async function getMenuVenueDetail(
    sessionStorageKey,
    venueId,
    endpoint
) {
    return createFetchwithSessionStorage(
        sessionStorageKey,
        MENU_VENUE,
        { id: venueId },
        endpoint
    )
}
